// 添加询单
import http from '../http';

//首页
export function messageIndexList () {
    return http({
        url: "/api/user/index",
        method: "GET",
    })
}

// 消息中心列表
export function noticeList (params) {
    return http({
        url: "/api/user/notice",
        method: "GET",
        params:params
    })
}

// 消息中心列表 删除
export function noticeDel (data) {
    return http({
        url: "/api/user/notice",
        method: "DELETE",
        data:data
    })
}


//自定义消息提醒 列表
export function customizeMessageList (params) {
    return http({
        url: "/api/message/message",
        method: "GET",
        params:params
    })
}

//自定义消息提醒 添加
export function customizeMessagePut (data) {
    return http({
        url: "/api/message/message",
        method: "PUT",
        data:data
    })
}

//自定义消息提醒 删除
export function customizeMessageDel (data) {
    return http({
        url: "/api/message/message",
        method: "DELETE",
        data:data
    })
}

//自定义消息提醒 完成
export function customizeMessageSub (data) {
    return http({
        url: "/api/message/message",
        method: "POST",
        data:data
    })
}



