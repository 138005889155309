<template>
  <div>

    <div class="content-tile mb-12" v-if="$route.name=='messageNotification'">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>自定义提醒列表</span>
      </div>
      <div>
        <el-button type="primary" @click="messageNotificationDialog=true">添加提醒</el-button>
      </div>
    </div>
    <message-table
        :customMessage="customMessage"
        :currentPage="currentPage"
        :total="total"
        @tabClick="tabClick"
        @subTableRow="subTableRow"
        @delTableRow="delTableRow"
        @batchDel="batchDel"
        @batchSub="batchSub"
        @pageCurrentChange="pageCurrentChange"
    />


    <!--dialog-->
    <el-dialog
        :append-to-body="true"
        title="添加提醒"
        :visible.sync="messageNotificationDialog"
        width="440px"
        :before-close="messageNotificationDialogClose"
    >
      <div class="messageNotification-dialog">
        <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="messageNotificationDialogData.content">
        </el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="messageNotificationDialogClose">取 消</el-button>
        <el-button type="primary" @click="messageNotificationDialogSub">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import MessageTable from '../components/MessageTable'
import {
  customizeMessageList,
  customizeMessagePut,
  customizeMessageSub,
  customizeMessageDel
} from '@/api/module/messageCenter'

export default {
  components: {MessageTable},
  props: [],
  data() {
    return {
      messageNotificationDialog: false, //添加提醒dialog
      messageNotificationDialogData: {
        content: ''
      },
      tabsActive: 2, //tabs默认选择哪个
      currentPage: 1,  // 当前页数
      total: 0,

      // 自定义消息提示
      customMessage: {
        tableData: [
      /*      {
          id: 1,
          messageContent: '早点起来吃饭',//消息内容
          creationTime: '2015-05-12 15:45:55',//创建时间
          status: 1, //状态，1 已完成 0未完成
        }*/
        ]
      }
    };
  },
  created() {
    this.initLoad()
  },
  mounted() {
  },
  methods: {
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        status: this.tabsActive,
        page:this.currentPage,
      }
      customizeMessageList(params).then((result) => {
        let res = result.data;
        this.currentPage = res.current_page;
        this.total = res.total;

        this.customMessage.tableData = res.data.map(item => ({
          id: item.id,
          messageContent: item.content,//消息内容
          creationTime: this.Utils.timeDate(item.created_at),//创建时间
          status: item.status, //状态，1 已完成 0未完成
        }));
      })
    },
    //,
    // 已完成
    subTableRow(id) {
      let data = {
        ids: id
      }
      customizeMessageSub(data).then(() => {
        this.$message.success('操作成功');
        this.initLoad(2)
      })
    },
    //删除
    delTableRow(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: id
        }
        this.$message.success('删除成功');
        customizeMessageDel(data).then(() => {
          this.initLoad();
        })
      }).catch(() => {
        this.$message.info('已取消删除');
      });

    },
    //批量删除
    batchDel(id) {
      if(id.length==0){
        this.$message({
          type: 'error',
          message: '请选择要删除的信息'
        });
        return false;
      }
      this.delTableRow(id.toString())
    },
    //批量完成
    batchSub(id) {
      if(id.length==0){
        this.$message({
          type: 'error',
          message: '请选择要完成的信息'
        });
        return false;
      }
      this.subTableRow(id.toString())
    },
    //tab点击
    tabClick(tab) {
      this.tabsActive = tab.name;
      this.currentPage=1;
      this.initLoad();
    },
    //分页
    pageCurrentChange(val){
      this.currentPage=val;
      this.initLoad()
    },
    //dialog  添加提醒 关闭 恢复初始值
    messageNotificationDialogClose() {
      this.messageNotificationDialogData = this.$options.data().messageNotificationDialogData;
      this.messageNotificationDialog = false;
    },
    //  dialog 提交
    messageNotificationDialogSub() {
      if(this.messageNotificationDialogData.content==''){
        this.$message.error('请填写信息');
        return false;
      }
      let data = {
        content: this.messageNotificationDialogData.content
      }
      customizeMessagePut(data).then(() => {
        this.$message.success('添加成功');
        this.initLoad();
      })
      this.messageNotificationDialogClose();
    }
  },
};
</script>

<style lang="scss" scoped></style>
