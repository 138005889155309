<template>
  <div  class="message-index-table">
    <!--<div class="title">-->
    <!--  <span>自定义消息提醒</span>-->
    <!--</div>-->

    <!--tab-->
    <div class="tab pt-12">
      <el-tabs v-model="tabActiveName" @tab-click="tabClick">
        <el-tab-pane name="1">
          <span slot="label">已完成</span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">未完成</span>
        </el-tab-pane>
      </el-tabs>
      <div class="tab-right">
        <el-button type="danger" @click="batchDel">批量删除</el-button>
        <el-button type="primary" @click="batchSub">批量完成</el-button>
      </div>
    </div>

    <div class="table">
      <!--表格-->
      <el-table
          ref="table"
          :data="customMessage.tableData"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :header-cell-style="{'color': '#18a78a'}"
          v-loading="$store.state.loading"
      >
        <el-table-column
            type="selection"
            min-width="40px"
            align="left"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="消息内容"
            prop="messageContent"
            min-width="170px"
            align="left"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="创建时间"
            prop="creationTime"
            width="170px"
            align="center"
            :show-overflow-tooltip="true">
        </el-table-column>
       <!-- <el-table-column
            label="状态"
            prop="status"
            width="170px"
            align="center"
            :show-overflow-tooltip="true"
        >
        </el-table-column>-->
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="subTableRow(scope.row.id)" v-if="scope.row.status==2">
              完成
            </el-button>
            <el-button type="danger" @click="delTableRow(scope.row.id)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--分页-->
    <div class="footerPage">
      <div>
        <!--<el-button type="primary">批量删除</el-button>-->
      </div>
      <el-pagination
          background
          @current-change="pageCurrentChange"
          :current-page="currentPage"
          :page-size="30"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>

export default {
  components: {},
  props: ['customMessage','currentPage','tableSelectId','total'],
  data() {
    return {
      tabActiveName:'2',
      tableSelectIdDialog:[]
    };
  },
  filters: {
    status
  },
  mounted() {
  },
  methods: {
    //tag点击切换
    tabClick(tab) {
      this.$emit('tabClick',tab);
    },
    //已完成
    subTableRow(id){
      this.$emit('subTableRow',id)
    },
    //删除
    delTableRow(id){
      this.$emit('delTableRow',id)
    },
    //批量删除
    batchDel(){
      this.$emit('batchDel',this.tableSelectIdDialog)
    },
    //批量完成
    batchSub(){
      this.$emit('batchSub',this.tableSelectIdDialog)
    },
    //表格 多选后的值
    handleSelectionChange(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.customMessage.tableData.forEach((val) => {
          if (value.id == val.id) {
            tableDataROW.push(value.id);
          }
        });
      });
      //把多选完后的数组保存起来
      this.tableSelectIdDialog = [];
      this.tableSelectIdDialog = tableDataROW;
    },

    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },

    //分页
    pageCurrentChange(val) {
      this.$emit('pageCurrentChange',val)
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-index-table {
  //margin-top: 12px;
  padding: 0 12px 12px;
  background: #C9F5F2;
}
//自定义消息提醒
.message-index-table {
  .title {
    //@include flex(row, nowrap, center, space-between);
    span {
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .tab {
    position: relative;

    &-right {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}
</style>
